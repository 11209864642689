





import Vue from 'vue';
// import moment from 'moment';
import _, { isString } from 'lodash';
// import qs from 'qs';
// import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { handleApiError, handleNodeServerError } from '~/util/common';
// import { $cookies } from '~/util/api';
// import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {},
  components: {},
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   const { keyword } = ctx.query;

  // },
  async asyncData(ctx: any) {
    try {
      const { keyword } = ctx.query;
      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';

      let apiErrorList: any = [];
      let requestList: any[] = [];

      requestList = [
        ctx.$axios.get('/api/sys/health/check'), //
      ];

      const result: any[] = await Promise.all(requestList);
      const [
        newsListRes, // 最新资讯
      ] = result;

      if (newsListRes.code === ResponseCode.SUCCESS) {
      } else {
        apiErrorList.push({
          code: newsListRes.code,
          message: newsListRes.message,
        });
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));
        return ctx.error({
          message: JSON.stringify({
            code: newsListRes.code,
            message: newsListRes.message,
          }),
          path: ctx.route.fullPath,
          statusCode: 500,
        });
      }

      return {
        lang,
        langApi,
        apiErrorList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      message: '',
      keyword: '',
      apiErrorList: [],
    };
  },
  computed: {},
  created(): void {},
  mounted() {
    // handleApiError(this.apiErrorList, '/system/error');
  },
  methods: {},
  // head(): Record<string, any> {

  // },
});
