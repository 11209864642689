



























































































































































































































































































import { Context } from '@nuxt/types';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import HotelSearch from '~/components/Hotel/Booking.vue';
import HotelItem from '~/components/Hotel/HotelItem.vue';
import Poster from './component/poster.vue'
import moment from 'moment';
import { getCodeByName, getNameByCode } from '~/components/Hotel/city-data';
import qs from 'qs';
import _ from 'lodash';
import {TraceCode} from "~/util/trace";

let timer: any = null;
export default Vue.extend<any, any, any>({
  components: {
    HotelSearch,
    HotelItem,
    Poster
  },
  name: 'Result',
  asyncData(ctx: Context) {
    const { status } = ctx.params;
    const { id } = ctx.query;
    return {
      status,
      id,
    };
  },
  data() {
    return {
      status: '',
      mode: 'ticket',
      seconds: 10,
      detail: {},
      hotelList: [],
      // ticketList: [],
      cooperateTypeList: [
        {
          label: '酒店预订',
          url: 'https://dcdn-jufair.jufair.com/service/apply_jiudian.jpeg',
          p1: '全网低价',
          p2: '安心订',
          p3: '放心住',
          src: this.$config.HOTEL,
        },
        // {
        //   label: '机票预订',
        //   url: 'https://dcdn-jufair.jufair.com/service/apply_jipiao.jpeg',
        //   p1: '便捷出行',
        //   p2: '即时出票',
        // },
        {
          label: '签证办理',
          url: 'https://dcdn-jufair.jufair.com/service/apply_qianzheng.jpeg',
          p1: '加急办理',
          p2: '专业客服',
          src: '/visa',
        },
        {
          label: '行程定制',
          url: 'https://dcdn-jufair.jufair.com/service/apply_xingcheng.jpeg',
          p1: '商务行程',
          p2: '个性定制',
          p3: '贴心服务',
        },
      ],
      showPoster: false,
    };
  },
  computed: {
    icon() {
      if (this.status === 'success') {
        return require('~/assets/images/success.png');
      } else {
        return require('~/assets/images/error.png');
      }
    },
    desc() {
      if (this.status === 'success') {
        return `展会将于${this.$data.detail.lunchTime}举办，请携带身份证原件刷卡入场或至现场服务台换领纸质进馆证！预祝观展顺利！`;
      } else {
        return '非常抱歉，预订失败。请您重新下单或联系客服处理。';
      }
    },
    optionName(): string {
      let pavilionName = _.get(this, 'detail.pavilionInfo.pavilionName', '');
      pavilionName = pavilionName?.replace(/（/g, '(')?.replace(/）/g, ')');

      return pavilionName;
    },
    hotelQuery(): string {
      const query = {
        adult: 1,
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(3, 'days').format('YYYY-MM-DD'),
        children: 0,
        display: this.detail.area,
        cityCode: getNameByCode(this.detail.area),
        crn: 1,
        optionName: this.optionName,
        starlist: '4,5',
      };

      return qs.stringify(query);
    },
  },
  created(): void {
    const { periodical, id }: any = this.$route.query;
    this.fetchDetail(id);
    // this.getTicketInfo();
    if (periodical) {
      this.mode = 'periodical';
      timer = setInterval(() => {
        if (this.seconds >= 1) {
          this.seconds--;
        }
        if (this.seconds <= 0) {
          // this.$router.push('/manager/periodical-my/');
        }
      }, 1000);
    } else {
      this.mode = 'ticket';
    }
  },
  mounted() {
    // this.fetchHotel();
    // 埋点
    if (this.mode === 'periodical') {
      window.trace?.upload({
        module: TraceCode.MO_FAIR_PERIODICAL,
        callbackCode: TraceCode.CC_PERIODICAL_PAY_SUCCESS,
        bizId: this.$route.query.id,
        bizOrderId: this.$route.query.orderNo,
      });
    } else if (this.mode === 'ticket') {
      window.trace?.upload({
        module: TraceCode.MO_FAIR_TICKET,
        callbackCode: TraceCode.CC_FAIR_PAY_SUCCESS,
        bizId: this.$route.query.id,
        bizOrderId: this.$route.query.orderNo,
      });
    }
  },
  beforeDestroy() {
    if (timer) {
      clearInterval(timer);
    }
  },
  methods: {
    moment,
    handlePoster(){
      this.showPoster = true
    },
    
    
    getTicketInfo() {
      let list = this.$store.state.ticket.ticketList;
      list.Map((item: any) => {
        this.ticketList.push(item.value.visitorName);
      });
    },
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios } = this;
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
    async fetchDetail(id: string | number) {
      const res: any = await this.$axios.get(`/api/exhibition/${id}`);
      if (res.code === ResponseCode.SUCCESS) {
        this.detail = res.data;
        // this.fetchHotel();
      }
    },
    goHotel() {
      window.open(`${this.$config.HOTEL}`, '_self');
    },
    goVisa() {
      this.$router.push({
        path: '/visa',
      });
    },
    goRouter(src: string) {
      if (src) {
        if (src === this.$config.HOTEL) {
          window.open(`${this.$config.HOTEL}`, '_self');
        } else {
          this.$router.push({
            path: src,
          });
        }
      } else {
        const { id }: any = this.$route.query;
        window.open(`/exhibition/apply/${id}.html?success=success`, '_blank');
      }
    },
    async fetchHotel() {
      const hotelListParams = {
        checkInDate: moment().format('YYYY-MM-DD'),
        checkOutDate: moment().add(3, 'days').format('YYYY-MM-DD'),
        adultNum: 1,
        cityCode: getCodeByName(this.detail.area),
        keyWords: this.optionName,
        hotelStar: '4,5',
        pageNo: 1,
        pageSize: 6,
        sortCode: 0, // 0：默认 1：低价优先 2：高价优先 3：距离优先 4：好评优先
      };

      const res = await this.$axios.post(
        '/api/aliTripApi/hotelList',
        hotelListParams
      );
      if (_.get(res, 'code') === ResponseCode.SUCCESS) {
        this.hotelList = _.get(res, 'data.body.module.items', []);
      }
    },
  },
  head(): Record<string, any> {
    return {
      title: `${this.status === 'success' ? '预订成功' : '预订失败'}-聚展`,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
