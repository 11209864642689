












































































































































import Vue from 'vue';
import moment from 'moment';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  filters: {
    areaFormat(str: string) {
      if (!str) return str;
      const len = str.replace(/ /g, '').length;
      if (len === 2) {
        return str + '展览会';
      } else if (len === 3) {
        return str + '展会';
      } else {
        return str + '展';
      }
    },
    lengthFormat(str: string) {
      if (!str) return str;
      const len = str.length;

      if (len > 16) {
        return str.slice(0, 16) + '...';
      } else {
        return str;
      }
    },
  },
  async asyncData(ctx) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const result: any[] = await Promise.all([
      ctx.$axios.get('/api/exhibition/city/0'),
      ctx.$axios.get('/api/exhibition/city/1'),
      ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
      ctx.$axios.post('/api/exhibitionPavilion/list', {
        locale: langApi,
        pageNum: 1,
        pageSize: 50,
      }),
    ]);

    const [chinaRes, areaRes, industryRes, pavilionRes] = result;

    let china: Record<string, any> = {};
    if (chinaRes.code === ResponseCode.SUCCESS) {
      china = chinaRes.data;
    }

    let area: Record<string, any> = {};
    if (areaRes.code === ResponseCode.SUCCESS) {
      area = areaRes.data;
    }

    let industry: Record<string, any> = {};
    if (industryRes.code === ResponseCode.SUCCESS) {
      industry = industryRes.data;
    }

    let pavilion: Record<string, any> = {};
    if (pavilionRes.code === ResponseCode.SUCCESS) {
      pavilion = pavilionRes.data;
    }

    return {
      industryList: industry,
      pavilionList: pavilion?.records || [],
      areaAll: china.concat(area),
    };
  },
  data() {
    const dateList: any[] = [];
    return {
      dateList,
      activeName: 'first',
      title: '行业',
    };
  },
  created(): void {
    this.creatDataList();
  },
  methods: {
    creatDataList() {
      const currentMonth = new Date().getMonth();
      const dateList: any[] = [];
      for (let i = -2; i < currentMonth + 23; i++) {
        const date = moment().add(i, 'months');
        dateList.push({
          label: date.format('YYYY年MM月'),
          value: date.format('YYYYMM'),
        });
      }
      this.dateList = dateList;
    },
    setTit(title: string) {
      this.title = title;
    },
  },
  head() {
    return {
      title: '网站地图-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
      ],
    };
  },
});
