




























































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import { TraceCode } from '~/util/trace';

const picture = (images: string) => {
  try {
    return images.split(';')[0];
  } catch (error) {
    if (process.client && 'fundebug' in window) {
      window.fundebug.notifyError(error);
    }
    return images;
  }
};
export default Vue.extend({
  filters: {
    firstLink(val: string): string {
      const [firstIndustryId, secondIndustryId, area, province, city] = val
        .split('-')
        .slice(1, 7);
      if (
        firstIndustryId === '0' &&
        secondIndustryId === '0' &&
        area === '0' &&
        province === '0' &&
        city === '0'
      ) {
        return '/pavilion/';
      } else {
        return val;
      }
    },
  },
  async asyncData(ctx) {
    const {
      f: firstIndustryId = '0',
      s: secondIndustryId = 0,
      g: area = 0,
      p: province = 0,
      c: city = 0,
      t: date = '0',
      page = 1,
    } = ctx.route.params;
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';

    const params: Record<string, any> = {
      locale: langApi,
      online: 1,
      pageNum: page,
      pageSize: 20,
    };

    const hotParams: Record<string, any> = {
      locale: langApi,
      recommond: '1',
      pageNum: 1,
      pageSize: 12,
      sortStr: 'scale',
      sortType: 'asc',
      selectTimeType: 1,
    };

    if (province > 0) {
      params.areaId = province;
    }

    const result: any[] = await Promise.all([
      ctx.$axios.post('/api/exhibitionPavilion/list', params),
      ctx.$axios.get(`/api/exhibition/city/1`),
      ctx.$axios.post('/api/exhibition/list', hotParams), // 热门推荐
    ]);

    const [pavilionRes, areaRes, hotFairRes] = result;
    let pavilion = [];
    if (pavilionRes.code === ResponseCode.SUCCESS) {
      pavilionRes.data.records.forEach((item: { picture: string }) => {
        item.picture = picture(item.picture);
      });
      pavilion = pavilionRes.data;
    }

    let areas = [];
    if (areaRes?.code === ResponseCode.SUCCESS) {
      areas = areaRes.data;
    }

    let hotFair = {};
    if (hotFairRes.code === ResponseCode.SUCCESS) {
      hotFair = hotFairRes.data;
    }

    let cityList = [];
    if (area > 0) {
      const item = areas.find((item: any) => item.id == area);
      cityList = item?.areas || [];
    }

    // 内链
    let innerChainActive: number = 0;
    const innerChainAllRes: any = await Promise.all([
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门地区',
        pageSize: 16,
        // locale: langApi,
      }),
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门行业',
        pageSize: 16,
      }),
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门展会',
        pageSize: 16,
      }),
    ]);

    const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
      innerChainAllRes;
    let innerChainAreaList = [];
    let innerChainIndustryList = [];
    let innerChainExhList = [];

    if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
      innerChainAreaList = innerChainAreaRes.data.records;
    }
    if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
      innerChainIndustryList = innerChainIndustryRes.data.records;
    }
    if (innerChainExhRes.code === ResponseCode.SUCCESS) {
      innerChainExhList = innerChainExhRes.data.records;
    }

    return {
      firstIndustryId,
      secondIndustryId,
      area: +area,
      province: +province,
      city,
      date,
      page,
      pavilion,
      hotFair,
      areaList: areas,
      cityList,
      innerChainActive,
      innerChainAreaList,
      innerChainIndustryList,
      innerChainExhList,
    };
  },
  data() {
    return {
      area: 0,
      moreArea: false, // 地区更多
      innerChainActive: 0,
    };
  },
  computed: {
    areas() {
      if (this.moreArea) {
        if (this.area === 0) {
          return this.$data.areaList;
        }
        return this.$data.cityList;
      } else {
        if (this.area === 0) {
          return this.$data.areaList?.slice(0, 8) || [];
        }
        return this.$data.cityList?.slice(0, 8) || [];
      }
    },
  },
  created() {
    this.queryAreaList(this.area);
  },
  mounted() {
    window.trace?.upload({
      module: TraceCode.MO_PAVILION,
      callbackCode: TraceCode.CC_PAVILION_LIST,
    });
  },
  methods: {
    picture,
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    changePager(page: number) {
      const { firstIndustryId, secondIndustryId, area, province, city, date } =
        this.$data;

      this.$router.push({
        path: `/pavilion-${firstIndustryId}-${secondIndustryId}-${area}-${province}-${city}-${date}-${page}/`,
        query: this.$route.query,
      });
    },
    handleAreaMore() {
      this.moreArea = !this.moreArea;
    },
    areaChange(value: number) {
      this.queryAreaList(value);
    },
    async queryAreaList(id: number) {
      if (+id > 1) {
        const area: any = this.$data.areaList.find(
          (item: any) => item.id === +id
        );
        this.$data.cityList = area.areas;
      } else if (+id === 1) {
        const res: any = await this.$axios.get('/api/exhibition/city/0');
        if (res.code === ResponseCode.SUCCESS) {
          this.$data.cityList = _.get(res, 'data[0].areas');
        }
      }
    },
  },
  head() {
    const { p: province = 0, page = 1 } = this.$route.params;

    let continentName = '国际';
    if (province > 0) {
      const city = this.$data.cityList.find(
        (item: any) => item.id === +province
      );
      continentName = city?.area?.replace(/ /g, '');
    }

    let _pageText = '';
    if (page > 1) {
      _pageText = `_第${page}页`;
    }

    return {
      title: `${continentName}会展中心_展览会馆_博物馆大全${_pageText}-聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${continentName}会展中心信息,${continentName}展览会馆排期,${continentName}展馆地址,${continentName}展馆乘车路线`,
        },
        {
          name: 'description',
          content:
            '聚展网为您提供全球展馆信息查询,展览会馆介绍,展览会馆地址,展览会馆线路,展览会馆联系方式。使你能够快速便捷的了解更多会展中心,展览馆等信息。',
        },
      ],
    };
  },
});
