





























































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'Terms',
  head(): Record<string, any> {
    return {
      title: '服务协议-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex,nofollow',
        },
      ],
    };
  },
});
