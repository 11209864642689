

























































































































































































































































































import Vue from 'vue';
import xss from 'xss';
import moment from 'moment';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';
import { getContent } from '~/util/common';
import JuMap from '~/components/Map/index.vue';
import 'vue-photo-preview/dist/skin.css';
import FairTag from '~/components/Goods/fairTag.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  components: {
    JuMap,
    FairTag,
  },
  async asyncData(ctx) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const { id } = ctx.route.params;
    const page = 1;

    const result: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibitionPavilion/${id}`),
      ctx.$axios.post('/api/exhibition/list', {
        locale: langApi,
        selectTimeType: 1,
        pavilionId: id,
        pageNum: page,
        pageSize: 20,
        time: moment().format('YYYY-MM-DD'),
      }),
    ]);

    const [detailRes, fairRes] = result;

    let detail: Record<string, any> = {};
    if (detailRes.code === ResponseCode.SUCCESS) {
      detail = detailRes.data || {};
      // online 0:未上架 1:上架
      if (detail.online !== 1) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    } else {
      return ctx.error({
        message: 'Page not found',
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }

    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data;
    }

    // 相关展会
    const eveFairRes: any = await ctx.$axios.post('/api/exhibition/list', {
      locale: langApi,
      selectTimeType: 1,
      area: _.get(detail, 'areaId', 0),
      regionType: _.get(detail, 'regionType', 0),
      pageNum: 1,
      pageSize: 10,
      time: moment().add(7, 'days').format('YYYY-MM-DD'),
    });
    let eveFair = {};
    if (eveFairRes.code === ResponseCode.SUCCESS) {
      eveFair = eveFairRes.data;
    }

    const dates = [];
    const month = new Date().getMonth() + 1;
    for (let i = month - 3; i < month + 6; i++) {
      dates.push(
        moment()
          .add(i - 3, 'month')
          .format('YYYY年MM月')
      );
    }

    return {
      langApi,
      page,
      detail,
      attribution: `&copy; <span>${
        _.get(detail, 'pavilionName', null) ||
        _.get(detail, 'pavilionEnglishName', null)
      }</span>`,
      center: [_.get(detail, 'latitude', 0), _.get(detail, 'longitude', 0)],
      fair,
      dates,
      eveFair,
    };
  },
  data() {
    return {
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 16,
      activeName: '展馆详情',
      date: moment().format('YYYY年MM月'),
      page: 1,
      fair: {},
      mapDialogShow: false,
      langApi: 'cn',
    };
  },
  computed: {
    picture(): string {
      return this.$data.detail?.picture?.split(';');
    },
    otherPicture(): any[] {
      try {
        return JSON.parse(this.$data.detail?.otherPicture);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        console.error('filters picture:::', this.$data.detail?.otherPicture);
        return [];
      }
    },
    showMap(): boolean {
      const { regionType, latitude, longitude } = this.$data.detail;

      if (regionType === 1) {
        return !!latitude && !!longitude;
      } else {
        return true;
      }
    },
  },
  mounted() {
    window.trace?.upload({
      module: TraceCode.MO_PAVILION,
      callbackCode: TraceCode.CC_PAVILION_DETAIL,
      bizId: this.$route.params.id,
    });
  },
  methods: {
    xss,
    getContent,
    handleDateChange() {
      this.queryFair();
    },
    getAddress() {
      this.mapDialogShow = true;
    },
    async queryFair() {
      const { id } = this.$route.params;
      const params: Record<string, any> = {
        pavilionId: id,
        pageNum: this.page || 1,
        pageSize: 20,
        selectTimeType: 1,
        locale: this.langApi,
      };

      if (this.date === '全部') {
        delete params.time;
      } else if (this.date === '当年') {
        params.time = moment().format('YYYY-01-01');
      } else if (this.date === '明年') {
        params.time = moment().add(1, 'year').format('YYYY-01-01');
      } else {
        params.time = this.date.replace(/年|月/g, '-') + '01';
      }

      const res: any = await this.$axios.post('/api/exhibition/list', params);
      if (res.code === ResponseCode.SUCCESS) {
        this.fair = res.data;
      }
    },
    async handlePageChange(page: number) {
      this.page = page;
      await this.queryFair();
      // 获取到目标位置距离
      this.$nextTick(() => {
        // 滑动到顶部
        (this.$refs.main as any).scrollIntoView();
      });
    },
  },
  head() {
    const { pavilionName = '', pavilionenglishname = '' } = this.$data.detail;
    const year = new Date().getFullYear();

    return {
      title: `${pavilionName}${pavilionenglishname}近期展会_排期表_地址_路线_介绍-聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${pavilionName}${year}年排期表,${pavilionName}近期展会信息介绍,乘车路线,地址信息`,
        },
        {
          name: 'description',
          content: `聚展网为您提供一站式订展服务,提供${pavilionName}${year}年展会信息,展会排期表,地址,乘车路线等信息。`,
        },
      ],
    };
  },
});
