



















import Vue from 'vue';
// import moment from 'moment';
import _, { isString } from 'lodash';
// import qs from 'qs';
// import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
// import { $cookies } from '~/util/api';
// import { TraceCode } from '~/util/trace';
import { mapState, mapMutations } from 'vuex';

export default Vue.extend({
  layout: 'errorLayout',
  filters: {},
  components: {},
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   const { keyword } = ctx.query;

  // },
  async asyncData(ctx) {},
  data(): Record<string, any> {
    return {
      keyword: '',
      url: '',
    };
  },
  computed: {
    ...mapState({
      apiErrorMessage: (state: any) => state.global.apiErrorMessage,
    }),
  },
  created(): void {},
  mounted() {
    let query = this.$route.query || null;
    if (query.hasOwnProperty('redirect'))
      this.url = decodeURIComponent(query.redirect as string);

    // ?autoRedirect&redirect=https%3A%2F%2Fvue.jufair.com%2Finformation%2F16355.html
    // 自动重定向 autoRedirect
    if (
      query.hasOwnProperty('autoRedirect') &&
      query.hasOwnProperty('redirect')
    ) {
      setTimeout(() => {
        let reEl: any = document.getElementById('refresh_button');

        reEl.click();
        window.location.replace(
          decodeURIComponent(query.redirect as any) as any
        );
      }, 100);
    }
  },
  methods: {},
  // head(): Record<string, any> {

  // },
});
