


























































import Vue from 'vue';
import { isString } from 'lodash';
import GoodsItem from '~/components/Goods/item.vue';
import { ResponseCode } from '~/util/constant';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  name: 'Index',
  components: { GoodsItem },
  async asyncData(ctx) {
    const result: Record<string, any> = {};
    const { id } = ctx.params;
    const resp = await Promise.all([
      ctx.$axios.get(`/api/specialTopicApi/${id}`),
      ctx.$axios.post(`/api/specialTopicApi/top`, { pageNo: 1, pageSize: 20 }),
    ]);
    const [topicRes, listRes] = resp as any[];
    if (topicRes.code === ResponseCode.SUCCESS && topicRes.data) {
      result.seo = {
        seoTitle: topicRes.data.seoTitle,
        seoKeyword: topicRes.data.seoKeyword,
        seoDesc: topicRes.data.seoDesc,
      };
      result.topicName = topicRes.data.specialTopicName;
      result.fairList = topicRes.data.exhibitionList;

      const promises =
        topicRes.data.newsList?.map((item: any) => {
          return ctx.$axios.get(`/ossApi/newsContent/${item.id}.html`);
        }) || [];

      const contents = await Promise.all(promises);

      // const contents: string[] = [];
      result.newsList = topicRes.data.newsList?.map(
        (item: any, index: number) => {
          const reg = /<[^>]+>/gi;
          if (isString((contents as string[])[index])) {
            item.content = (contents as string[])[index]
              ?.replace(reg, '')
              ?.replace(/&nbsp;/g, '')
              ?.trim();
          } else {
            item.content = '';
          }
          return item;
        }
      );
    } else {
      result.fairList = [];
      result.newsList = [];

      return ctx.error({
        message: 'Page not found',
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }

    if (listRes.code === ResponseCode.SUCCESS) {
      result.list = listRes.data.records;
    } else {
      result.list = [];
    }

    return result;
  },
  head() {
    const { seo = {} } = this.$data;

    return {
      title: seo?.seoTitle,
      meta: [
        {
          name: 'keywords',
          content: seo?.seoKeyword,
        },
        {
          name: 'description',
          content: seo?.seoDesc,
        },
      ],
    };
  },
  methods: {
    handleNewsUrl,
  },
});
