


























































































import Vue from 'vue';
import _ from 'lodash';
import patTicketMixin from './pay-ticket-mixin';
import patPeriodicalMixin from './pay-periodical-mixin';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  name: 'Payment',
  mixins: [patTicketMixin, patPeriodicalMixin],
  async asyncData(ctx) {
    const { id, mode, type, orderNo = '', num = 1 } = ctx.query;

    if (mode === 'ticket') {
      const result: any[] = await Promise.all([
        ctx.$axios.get(`/api/exhibition/${id}`),
        ctx.$axios.get(`/api/ticket/query/${id}`),
      ]);

      const [fairRes, ticketRes] = result;

      let fair = {};
      if (fairRes.code === ResponseCode.SUCCESS) {
        fair = fairRes.data;
      }

      let ticket = {};
      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticket = ticketRes.data;
      }

      return {
        fairId: id,
        type,
        mode,
        fair,
        ticket,
        orderNo,
        num,
      };
    } else if (mode === 'pdf') {
      const result: any[] = await Promise.all([
        ctx.$axios.get(`/api/exhibition/${id}`),
      ]);

      const [fairRes] = result;

      let fair = {};
      if (fairRes.code === ResponseCode.SUCCESS) {
        fair = fairRes.data;
      }

      return {
        fairId: id,
        type,
        mode,
        fair,
        orderNo,
      };
    }
  },
  data() {
    return {
      payType: 'alipay',
      mode: '',
      type: '',
      ticket: {},
      disabled: false,
    };
  },
  computed: {
    discountPrice(): number | string {
      const num = _.get(this.$route, 'query.num', 1) || 1;
      let discount = 0.05;
      if (num >= 2 && num <= 5) {
        discount = 0.1;
      } else if (num > 5) {
        discount = 0.15;
      }

      return (this.price * discount)?.toFixed(2);
    },
    price(): number {
      let _price = 0;
      if (this.mode === 'ticket') {
        const num = _.get(this.$route, 'query.num', 1) || 1;
        const singleId = _.get(this.$route, 'query.singleId', '');
        if (this.type === '1') {
          const singleDayTicketList = _.get(
            this,
            'ticket.singleDayTicketList',
            []
          );
          const singleDay = singleDayTicketList.find(
            (item: any) => `${item.id}` === singleId
          );
          _price = _.get(singleDay, 'singleDayPrice', 0);
        } else if (this.type === '2') {
          _price = _.get(this.ticket, 'periodPrice', 0);
        }

        return _price * (num as number);
      } else if (this.mode === 'pdf') {
        const order = this.$store.state.order.periodical;

        return order.totalAmount;
      }
      return _price;
    },
  },
  methods: {
    togglePayment(type: string) {
      this.payType = type;
      if (type === 'alipay') {
        this.$data.showBtn = true;
      }
    },
    back() {
      this.$router.back();
    },
  },
  head() {
    return {
      title: `支付-聚展`,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
